.top {
	padding: 5px 10px;
	ul {
		display: flex;
		flex-direction: row; }
	li {
		margin-right: 20px;
		&:first-child {
			margin-right: 0;
			flex: 1 1 auto; }
		&:last-child {
			margin-right: 0; } } }

.errorMessage {
	color: red; }
